import { fetchUtils } from "react-admin";
import { stringify } from "query-string";
import {
  is_empty,
  checkOffline,
  isFunction,
  isDefined,
  dataExists,
} from "../utils/validations";
import {
  getGuestUsername,
  getUserID,
  getUserTimezone,
  onUserLogout,
  setUuid,
} from "../utils/AuthUtil";
import { getParameterByName, is_class } from "../utils/Utils";
import constants from "../constants/constants";
import { app_route_ids, app_route_keys } from "constants/urlPaths";
import {
  getRAUrlPathName,
  getRAUrlParams,
  appendUrlParams,
} from "utils/urlUtils";
import { matchPath } from "react-router";
import { recordedContentAPI } from "schedule-v2/RecordedContent/recordedContent.api";
import { resource_apis } from "data/resourceApis";
import moment from "moment";
import SessionStorageConstants from "constants/SessionStorage.constants";
import {
  HTTP_STATUS_SUCCESS_CODES,
  INTERNAL_STATUS_CODES,
  answers_export_resources,
  use_db_index,
} from "./api.constants";
import pako from "pako";
import * as Sentry from "@sentry/react";
import { CUSTOM_RESPONSE_APIS } from "./customResponseApis";
import { UPDATE_CREATOR_DATA_API } from "features/Common/modules/CreatorData/CreatorData.constants";
import api from "./APIs";
import { SESSION_STORAGE_KEYS } from "features/Common/modules/Storage/modules/SessionStorage/constants/SessionStorage.constant";

const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}`;

const fetchJwt = async (
  grant_type = "authorization_code",
  url = null,
  options = null
) => {
  const staffEmail = localStorage.getItem("bdEmail");
  try {
    if (
      sessionStorage.getItem("proccessingJWT") &&
      grant_type !== "new_authorization_code"
    )
      return;
    if (grant_type === "new_authorization_code")
      grant_type = "authorization_code";
    sessionStorage.setItem("proccessingJWT", "true");
    let username = localStorage.getItem("token_uuid");

    const api_response = await fetch(`${apiUrl}/users/auth/token`, {
      headers: {
        "auth-token": "eyJtZXNzYWdlIjoiSldUIFJ1bGVzISIsImlhdCI6MTQ1OTQ0ODE",
        "scoot-origin": "web_app",
      },
      method: "POST",
      body: JSON.stringify({
        client_id: username,
        grant_type: grant_type,
        refresh_token:
          grant_type === "refresh_token"
            ? localStorage.getItem("refresh_token")
            : "",
        staff_email: staffEmail,
      }),
    });
    if (api_response.status !== 500) {
      const response = await api_response.json();
      if (response?.status === 200) {
        if (!is_empty(response?.data?.staff_details?.main_user)) {
          setUuid(response?.data?.staff_details?.main_user);
        }
        if (!is_empty(response?.data?.staff_details?.org_uuid)) {
          localStorage.setItem(
            "org_uuid",
            response?.data?.staff_details?.org_uuid
          );
        }
        const { access_token, refresh_token } = response.data.tokens;
        localStorage.setItem("access_token", access_token);
        localStorage.setItem("refresh_token", refresh_token);
        if (!is_empty(url) && !is_empty(options)) {
          return window.location.reload();
        } else {
          return true;
        }
      } else if (response?.status === 7) {
        return fetchJwt("new_authorization_code", url, options);
      } else {
        Sentry.captureMessage(
          `Unknown JWT status - API response : ${JSON.stringify(response)}`
        );
        return onUserLogout(true);
      }
    } else {
      throw new Error(`JWT api_response status: ${api_response.status}`);
    }
  } catch (err) {
    Sentry.captureException(err);
    return onUserLogout(true);
  }
};

const fetchJson = async (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  } else {
    options.headers.set("Accept", "application/json");
  }
  // add your own headers here
  options.headers.set(
    "auth-token",
    "eyJtZXNzYWdlIjoiSldUIFJ1bGVzISIsImlhdCI6MTQ1OTQ0ODE"
  );
  options.headers.set("scoot-origin", "web_app");
  options.headers.set("exly-source", "creator_tool");
  if (!is_empty(localStorage.getItem("access_token")))
    options.headers.set("jwt-token", localStorage.getItem("access_token"));
  options.headers.set(
    "user-timezone",
    !is_empty(getUserTimezone()) ? getUserTimezone() : "Asia/Kolkata"
  );

  return fetchUtils
    .fetchJson(url, options)
    .then((data) => {
      sessionStorage.removeItem("proccessingJWT");
      return data;
    })
    .catch(async (error) => {
      checkOffline();
      if (
        error?.body?.status === 6 ||
        error?.body?.status === 2 ||
        error?.body?.status === 1 ||
        error?.body?.status === 10 ||
        error?.body?.status === 401
      ) {
        return onUserLogout(true);
      } else if (
        error.body &&
        (error.body.status === 4 || error.body.status === 5)
      ) {
        return fetchJwt("refresh_token", url, options);
      } else if (
        error?.body?.status === 3 ||
        error?.body?.status === 7 ||
        error?.body?.status === 8
      ) {
        return fetchJwt("authorization_code", url, options);
      } else if (
        error?.body?.status ===
          HTTP_STATUS_SUCCESS_CODES.HTTP_STATUS_NO_CONTENT ||
        error?.body?.status === INTERNAL_STATUS_CODES.NOT_MODIFIED_STATUS_CODE
      ) {
        return { json: error.body };
      } else {
        console.log(error);
        throw error;
      }
    });
};

const httpClient = fetchJson;

/*
  @dev to add any api's and handling in getList use resourse_apis[resource] format
*/
export default {
  fetchJwt,
  getList: (resource, params) => {
    let alt_resource;
    let query = {};
    const pathname = getRAUrlPathName();
    const urlParams = getRAUrlParams();

    const promotionTypeResourceArray = [
      app_route_ids[app_route_keys.upsell_details],
      app_route_ids[app_route_keys.multiple_purchases_details],
      app_route_ids[app_route_keys.attendees_details],
    ];

    if (promotionTypeResourceArray.includes(resource)) {
      const listing_uuid = localStorage.getItem(
        SessionStorageConstants.PROMOTIONAL_LISTING
      );

      const has_multiple_purchases = sessionStorage.getItem(
        SessionStorageConstants.MULTIPLE_PURCHASES
      );
      const entity_data = JSON.parse(
        sessionStorage.getItem(SessionStorageConstants.ENTITY_DATA)
      );

      if (
        !is_empty(listing_uuid) &&
        (!is_empty(params?.filter) || !is_empty(has_multiple_purchases))
      ) {
        query.listing_uuid = listing_uuid;
        //in case of plan,plan_uuid is needed to get listing customer stats
        if (entity_data) {
          query.plan_uuid = entity_data.uuid;
        }
        if (
          resource === app_route_ids[app_route_keys.multiple_purchases_details]
        ) {
          query.has_multiple_purchases = has_multiple_purchases || false;
        }
        alt_resource = api.order_bumps.customer_payment_details;
      } else return Promise.resolve();
    }

    if (!is_empty(params) && !is_empty(params.pagination)) {
      const { page, perPage } = params.pagination;
      if (
        !is_empty(perPage) &&
        !is_empty(
          constants.per_page_options.find(
            (item) => perPage === parseInt(item.value)
          )
        )
      ) {
        query.page_size = perPage;
      }
      // getUserChecks returns promise so directily getting from session storage.
      // Not making this getList function async
      let featureActivation = sessionStorage.getItem(
        SESSION_STORAGE_KEYS.USER_CHECKS
      );
      let premium_check = false;
      if (!is_empty(featureActivation)) {
        featureActivation = JSON.parse(featureActivation);
        premium_check = featureActivation?.is_premium;
      }
      query.page =
        params.pagination.perPage !== 1000 || !premium_check ? page : "all";
      if (
        answers_export_resources.includes(resource) &&
        params?.pagination?.perPage === 1000
      ) {
        query.expanded = true;
      }
    }

    if (resource === "coupons") {
      return httpClient(
        `${apiUrl}/payments/exly/discounts/paginated/list?${stringify(query)}`
      ).then(({ json }) => {
        json.data.discounts.forEach(
          (discount) => (discount.id = discount.uuid)
        );

        return {
          data: json.data.discounts,
          total: json.data.total,
        };
      });
    }

    if (resource === "campaignsLeads") {
      alt_resource = "host/v2/interest/list";
      const ad_campaign = JSON.parse(
        sessionStorage.getItem("ad_campaign_detail")
      );
      query.ad_campaign = ad_campaign?.uuid;
    }

    // TODO: @Akash This should be changed to resource_apis[resource] format
    if (resource === "campaignsCustomers") {
      let listing_customers_type = Number(
        sessionStorage.getItem("listing_customers_type")
      );

      const isNoSchedSub = JSON.parse(
        sessionStorage.getItem("listing_customers_is_no_schedule_subscription")
      );

      if (is_class({ type: listing_customers_type })) {
        alt_resource = "host/creator/batch/participants";
      } else if (
        listing_customers_type === constants.scheduleTypesId.no_schedule &&
        isNoSchedSub
      ) {
        alt_resource = "host/creator/batch/participants";
      } else if (
        listing_customers_type === constants.scheduleTypesId.telegram ||
        listing_customers_type === constants.scheduleTypesId.whatsapp
      ) {
        alt_resource = "host/creator/batch/participants";
      } else {
        alt_resource = "host/creator/listings/participants";
      }

      const ad_campaign = JSON.parse(
        sessionStorage.getItem("ad_campaign_detail")
      );
      query.listing_uuid = sessionStorage.getItem("listing_customers_id");
      query.ad_campaign = ad_campaign?.uuid;
    }

    if (resource === "listingCustomers" || resource === "classCustomers") {
      let listing_customers_type = Number(
        sessionStorage.getItem("listing_customers_type")
      );

      const isNoSchedSub = JSON.parse(
        sessionStorage.getItem("listing_customers_is_no_schedule_subscription")
      );

      // TODO: @Dhruv This should be changed to resource_apis[resource] format
      if (is_class({ type: listing_customers_type })) {
        alt_resource = "host/creator/batch/participants";
      } else if (
        listing_customers_type === constants.scheduleTypesId.no_schedule &&
        isNoSchedSub
      ) {
        alt_resource = "host/creator/batch/participants";
      } else if (
        listing_customers_type === constants.scheduleTypesId.telegram ||
        listing_customers_type === constants.scheduleTypesId.whatsapp
      ) {
        alt_resource = "host/creator/batch/participants";
      } else {
        alt_resource = "host/creator/listings/participants";
      }

      const ad_campaign = JSON.parse(
        sessionStorage.getItem("ad_campaign_detail")
      );
      query.listing_uuid = sessionStorage.getItem("listing_customers_id");
      query.ad_campaign = ad_campaign?.uuid;
    }

    if (resource === "my-bookings") {
      alt_resource = api.transaction_list;
      query.section_name = "bookings";
    }

    if (resource === "payments/exly/subscription/list") {
      alt_resource = api.subscription_list;
    }

    if (resource === "Reports") {
      alt_resource = api.list_reports;
    }

    if (resource === "team") {
      alt_resource = api.get_staffs;
      query.sub_domain = JSON.parse(localStorage.getItem("auth")).sub_domain;
    }

    if (resource === "Blogs") {
      alt_resource = api.handle_blog;
    }

    if (resource === "Referrals") {
      alt_resource = api.get_referral_list;
    }

    if (resource === app_route_ids[app_route_keys.scheduled_emails]) {
      alt_resource = api.get_past_email;
      query.trigger_completed = false;
      query.is_scheduled_mail = true;
    }

    if (resource === `${api.segments.get_segment_list}/customers`) {
      alt_resource = api.segments.get_segment_list;
      query.target = constants.segments_target_type.customers;
    }

    if (resource === `${api.segments.get_segment_list}/leads`) {
      alt_resource = api.segments.get_segment_list;
      query.target = constants.segments_target_type.leads;
    }

    if (resource === api.get_past_email) {
      alt_resource = api.get_past_email;
      query.trigger_completed = true;
      query.segment_uid = params.filter.segment_uid;
    }

    if (resource === app_route_ids[app_route_keys.zoom_attendance]) {
      alt_resource = api.attendance.get_attendance_list;
    }

    if (resource === app_route_ids[app_route_keys.live_webinar_attendance]) {
      alt_resource = api.attendance.get_attendance_list_of_listing;
      const matchResults = matchPath(pathname, {
        path: app_route_ids[app_route_keys.live_webinar_attendance],
      });
      query.listing_uuid = matchResults?.params?.listing_uuid;
      query.slot_uuid = matchResults?.params?.slotUuid;
    }

    if (resource === app_route_ids[app_route_keys.zoom_attendance_of_listing]) {
      alt_resource = api.attendance.get_attendance_list_of_listing;
      const matchResults = matchPath(pathname, {
        path: app_route_ids[app_route_keys.zoom_attendance_of_listing],
      });
      query.listing_uuid = matchResults?.params?.listing_uuid;
    }

    if (
      resource ===
      app_route_ids[app_route_keys.zoom_attendance_of_listing_for_customer]
    ) {
      alt_resource = api.attendance.get_attendance_of_customer_of_listing;
      const matchResults = matchPath(pathname, {
        path: app_route_ids[app_route_keys.zoom_attendance_of_listing],
      });
      query.listing_uuid = matchResults?.params?.listing_uuid;
      query.exly_user_uuid = urlParams.get("customer_uuid");
    }

    if (
      resource ===
      `${api.listingSpecificCommunication.get_offering_details_sessions_by_id}`
    ) {
      const matchResults = matchPath(pathname, {
        path: api.listingSpecificCommunication
          .get_offering_details_sessions_by_id,
      });
      query.listing_uuid = matchResults?.params?.listing_uuid;
      alt_resource =
        api.listingSpecificCommunication.get_offering_details_sessions;
      // We are fetching attendance of today plus 2 months max
      query.end_date = moment(new Date()).add(60, "d").format("YYYY-MM-DD");
    }

    if (!is_empty(params) && !is_empty(params.filter)) {
      for (let key in params.filter) {
        if (
          !is_empty(params.filter[key]) &&
          params.filter[key].constructor === String
        ) {
          params.filter[key] = params.filter[key].trim();
        }
      }
      Object.assign(query, params.filter);
    }

    if (resource === api.content_purchases_list) {
      query.guest__username = getGuestUsername();
    }
    if (resource === api.customer_activity) {
      query.guest_username = getGuestUsername();
    }

    if (!is_empty(getParameterByName("custom_filter"))) {
      let custom_filters = getParameterByName("custom_filter").split("|");
      for (let item of custom_filters) {
        query[item] = getParameterByName(item);
      }
    }
    if (resource === app_route_ids[app_route_keys.my_subscriptions])
      alt_resource = api.creator_subscription.get_creator_subscription;

    // Checking if the mapping is to a function then we send the following values
    const endPointData = isFunction(resource_apis[resource])
      ? resource_apis[resource]({ pathname, urlParams, params })
      : resource_apis[resource];
    const { api_end_point, queries } = endPointData || {};

    if (api_end_point) {
      alt_resource = api_end_point;
    }

    if (isDefined(queries)) {
      query = { ...query, ...queries };
    }

    let url = `${apiUrl}/${
      is_empty(alt_resource) ? resource : alt_resource
    }?${stringify(query)}`;

    if (resource === app_route_ids[app_route_keys.course_analytics]) {
      const matchResults = matchPath(pathname, {
        path: app_route_ids[app_route_keys.course_analytics],
      });
      query.listing_uuid = matchResults?.params?.listing_uuid;
      url = `${apiUrl}/${recordedContentAPI.course_analytics.list}?${stringify(
        query
      )}`;
    }

    if (resource === app_route_ids[app_route_keys.recorded_content_responses]) {
      const matchResults = matchPath(pathname, {
        path: app_route_ids[app_route_keys.recorded_content_responses],
      });
      query.listing_uid = matchResults?.params?.listing_uuid;
      query.booking_uid = matchResults?.params?.booking_uuid;
      const type = matchResults?.params?.type;
      url = `${apiUrl}/${recordedContentAPI.report(type)}?${stringify(query)}`;
    }

    if (resource === "payments/exly/demo/customers") {
      url = `${apiUrl}/host/v2/interest/list?lead_type=2&${stringify(query)}`;
    }

    if (resource === "host/interest/list") {
      url = `${apiUrl}/host/v2/interest/list?lead_type=1&${stringify(query)}`;
    }
    if (resource === api.transaction_list) {
      const searchParams = Object.fromEntries(getRAUrlParams());
      if (
        searchParams?.utm_info__affiliate ||
        searchParams?.dynamic_link_id ||
        searchParams?.feature_discount_info__early_bird_id
      ) {
        url = appendUrlParams(`${apiUrl}/${api.transaction_list}`, {
          ...(searchParams?.utm_info__affiliate && {
            utm_info__affiliate: searchParams.utm_info__affiliate,
          }),
          ...(searchParams?.dynamic_link_id && {
            dynamic_link_id: searchParams.dynamic_link_id,
          }),
          ...(searchParams?.feature_discount_info__early_bird_id && {
            feature_discount_info__early_bird_id:
              searchParams.feature_discount_info__early_bird_id,
          }),
        });
      }
    }

    return httpClient(url).then(({ json }) => {
      let list_data;
      if (resource === "host/statistics") {
        json.data.id = "stat";
        return {
          data: [json.data],
          total: 1,
        };
      }
      for (let key in json.data) {
        if (json.data[key].constructor === Array) {
          list_data = json.data[key];
          break;
        }
      }
      if (!is_empty(list_data)) {
        for (let i = 0; i < list_data.length; i++) {
          let data = list_data[i];
          if (!use_db_index.includes(resource)) {
            data.id = i;
          }
        }
      }

      const response = {
        data: list_data,
        total: json.data.total ? json.data.total : json.data.total_pages * 50,
        ...(CUSTOM_RESPONSE_APIS[resource] &&
          CUSTOM_RESPONSE_APIS[resource]({ listData: list_data })),
      };

      return response;
    });
  },

  getOne: (resource, params) => {
    if (resource === "/users/cancellation") {
      return httpClient(`${apiUrl}${resource}/get`).then(({ json }) => {
        json.data.id = json.data.cancellation_policy.hours;
        return {
          data: json,
        };
      });
    }

    return httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => {
      let data;
      for (let key in json.data) {
        if (json.data[key].constructor === Object) {
          data = json.data[key];
          break;
        }
      }
      data.id = params.id;
      return {
        data: data,
      };
    });
  },

  getMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    return httpClient(url).then(({ json }) => ({ data: json }));
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => ({
      data: json,
      total: parseInt(headers.get("content-range").split("/").pop(), 10),
    }));
  },

  update: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "PUT",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json })),

  updateMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: "PUT",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

  updateWebpageDetails: (params) => {
    let username = getUserID();
    params["source"] = "exly";
    return httpClient(`${apiUrl}/${UPDATE_CREATOR_DATA_API}/${username}`, {
      method: "POST",
      body: JSON.stringify(params),
    })
      .then((response) => response)
      .then((data) => {
        return {
          data: data,
        };
      });
  },

  create: (resource, params) =>
    httpClient(`${apiUrl}/${resource}`, {
      method: "POST",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    })),

  delete: (resource, params) => {
    if (resource === "coupons") {
      params = { uuid: params.previousData.uuid };

      return httpClient(`${apiUrl}/payments/exly/discounts/delete`, {
        method: "POST",
        body: JSON.stringify(params),
      }).then(({ json }) => ({ data: json }));
    }
    return httpClient(`${apiUrl}/${resource}/${params.previousData.uuid}`, {
      method: "DELETE",
    }).then(({ json }) => ({ data: json }));
  },

  deleteMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: "DELETE",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

  /**
   *
   * @param {*} resource exly api endpoint which should be stored in a constant
   * @param {*} method HTTP method
   * @param {*} params Api Payload
   * @param {*} gzip_encoding boolean: whether to enable gzip compression for your POST call; by default false
   * @param {*} signal AbortSignal: abort singal to abort the request
   * @returns API response
   */
  custom_request: (
    resource,
    method,
    params = {},
    gzip_encoding = false,
    signal = null
  ) => {
    let req_params = { method: method, signal: signal ?? undefined };
    let url = `${apiUrl}/${resource}`;

    if (!is_empty(params) && method === "GET") {
      url = `${apiUrl}/${resource}?${stringify(params)}`;
    }
    if (!is_empty(params) && method !== "GET") {
      let payload = JSON.stringify(params);
      if (gzip_encoding) {
        payload = pako.gzip(payload);
        req_params.headers = new Headers({ "Content-Encoding": "gzip" });
      }
      Object.assign(req_params, { body: payload });
    }

    return httpClient(url, req_params).then((response) => {
      if (!is_empty(response)) {
        return response.json;
      }
    });
  },
  custom_request_with_baseUrl: (
    resource,
    method,
    params = {},
    baseUrl = apiUrl,
    gzip_encoding = false
  ) => {
    let req_params = { method: method };
    let url = `${baseUrl}/${resource}`;

    if (!is_empty(params) && method === "GET") {
      url = `${baseUrl}/${resource}?${stringify(params)}`;
    }
    if (!is_empty(params) && method !== "GET") {
      let payload = JSON.stringify(params);
      if (gzip_encoding) {
        payload = pako.gzip(payload);
        req_params.headers = new Headers({ "Content-Encoding": "gzip" });
      }
      Object.assign(req_params, { body: payload });
    }

    return httpClient(url, req_params).then((response) => {
      if (!is_empty(response)) {
        return response.json;
      }
    });
  },

  getPDF: (resource, method, params = {}) => {
    let req_params = { method: method };
    let url = `${apiUrl}/${resource}`;

    if (!is_empty(params) && method === "GET") {
      url = `${apiUrl}/${resource}?${stringify(params)}`;
    }

    if (!is_empty(params) && method !== "GET")
      Object.assign(req_params, { body: JSON.stringify(params) });

    return httpClient(url, req_params).then((response) => {
      if (!is_empty(response)) {
        return response;
      }
    });
  },

  sendOtp: (params, options) => {
    let data = {
      email: params?.email,
      isSignUp: params?.isSignUp,
    };
    if (dataExists(params?.phone_number)) {
      data.phone_number = params.phone_number;
    }
    if (dataExists(params?.country_code)) {
      data.country_code = params.country_code;
    }
    if (dataExists(params?.name)) {
      data.name = params.name;
    }
    if (options?.resend === true) {
      data.resend = true;
    }
    return httpClient(`${apiUrl}/users/generate/otp`, {
      method: "POST",
      body: JSON.stringify(data),
    }).then(({ json }) => ({
      data: json,
    }));
  },

  handleGoogleSync: async () => {
    if (!is_empty(window.localStorage.getItem("calendar_sync"))) {
      window.localStorage.removeItem("calendar_sync");
      return httpClient(`${apiUrl}/${api.google_sync}`, {
        method: "POST",
        body: "",
      }).then((response) => {
        if (!is_empty(response)) {
          return response.json;
        }
      });
    }
    const status = await (
      await httpClient(`${apiUrl}/${api.check_google_integration}`, {
        method: "GET",
      })
    ).json;

    if (status.status === 200) {
      if (status.data.is_valid === false) {
        const redirect = await (
          await httpClient(`${apiUrl}/${api.calendar_initiate}`, {
            method: "POST",
            body: JSON.stringify({
              return_url: `${window.location.href}`,
            }),
          })
        ).json;
        if (redirect.status === 200 && !is_empty(redirect.data.redirect_url)) {
          window.localStorage.setItem("calendar_sync", "true");
          window.location.href = redirect.data.redirect_url;
        }
      } else {
        window.localStorage.removeItem("calendar_sync");
        return httpClient(`${apiUrl}/${api.google_sync}`, {
          method: "POST",
          body: "",
        }).then((response) => {
          if (!is_empty(response)) {
            return response.json;
          }
        });
      }
    }
  },

  // eslint-disable-next-line no-unused-vars
  postAnalyticalEvent: async (title, actionType, payload) => {
    // This has been disabled as an urgent patch to disable analytics calls from this project
    // Currently just returning a dummy payload to avoid having to remove anaytics from everywhere
    // TODO: Remove all calls to this function if not being used by Nov 7, 2020
    return await {
      data: {
        status: "success",
      },
      status: 200,
      message: "success",
    };
    // try {
    //     const window_again = typeof window !== "undefined" && window;
    //     let get_session_id =
    //         window_again.sessionStorage.getItem("host_session_id");
    //     if (is_empty(get_session_id)) {
    //         get_session_id = moment().toISOString();
    //         window_again.sessionStorage.setItem(
    //             "host_session_id",
    //             get_session_id
    //         );
    //     }
    //     let utm = window.sessionStorage.getItem("utm_details");
    //     if (!is_empty(utm)) {
    //         utm = JSON.parse(utm);
    //         Object.assign(payload, utm);
    //     }

    //     payload.session_id = get_session_id;
    //     payload.screen = title;
    //     payload.action = "Tap";
    //     payload.funnel = "creatorPage";
    //     payload.event_name = actionType;
    //     payload.platform =
    //         window.screen.width > 780 ? "desktopWeb" : "mobileWeb";
    //     if (payload.booking_meta) {
    //         if (typeof payload.booking_meta === "string") {
    //             payload.booking_meta = payload.booking_meta;
    //         } else {
    //             payload.booking_meta = payload.booking_meta.uuid;
    //         }
    //     }
    //     if (payload.user_meta) {
    //         payload.user_meta = payload.user_meta.username;
    //     }
    //     if (payload.event_meta) {
    //         payload.event_meta = payload.event_meta.uuid;
    //     }
    //     payload.source_url = window_again.location.href;
    //     let auth = JSON.parse(localStorage.getItem("auth"));
    //     if (!is_empty(auth) && !is_empty(auth.sub_domain)) {
    //         payload.origin = auth.sub_domain;
    //     } else {
    //         payload.origin = "";
    //     }
    //     return httpClient(`${apiUrl}/analytics/event/post`, {
    //         method: "POST",
    //         body: JSON.stringify(payload),
    //     }).then((response) => {
    //         if (!is_empty(response)) {
    //             return response.json;
    //         }
    //     });
    // } catch (error) {
    //     console.log(error);
    // }
  },

  sendPixelEvent: async (params) => {
    const uuid = localStorage.getItem("uuid");
    if (is_empty(uuid) || is_empty(params.event_name))
      return console.log("sendPixelEvent invalid request");
    let payload = {
      event_name: params.event_name,
      user_id: uuid,
      custom_data: params.custom_data,
    };

    httpClient(`${apiUrl}/analytics/pixel/event/post`, {
      method: "POST",
      body: JSON.stringify(payload),
    }).then((response) => {
      if (!is_empty(response)) {
        return response.json;
      }
    });
  },
};
